(function($, window, document) {
  /**
   * Loader
   *************************************************/
  $('#loader').wordSwitchLoader();

  // jQuery 3+
  $(function() {
    /**
     * Global Variables
     *************************************************/
    const $body = $('body');

    /**
     * Misc
     *************************************************/
    // body class
    $body.addClass('ready');
  }); // ready

  // jQuery 3+
  $(window).on('load', function() {
    /**
     * Global Variables
     *************************************************/
    const $body = $('body');
    const $logoContainer = $('#logo-container');
    const duration = {
      short: 195,
      medium: 225,
      long: 375,
      scroll: 375
    };

    /**
     * Scroll To
     *
     * @param {HTMLElement} target
     * @param {number} [offset=0]
     */
    function scrollTo(target, offset = 0) {
      let targetOffset = 0;

      // add logo container height to the target offset
      if (offset) {
        targetOffset += $logoContainer.outerHeight();
      }

      // scroll
      $(target).velocity('scroll', {
        offset: -targetOffset,
        duration: duration.scroll,
        mobileHA: false
      });
    }

    /**
     * Misc
     *************************************************/
    // body class
    $body.addClass('loaded');

    /**
     * Main Menu
     *************************************************/
    const $mainMenuTrigger = $('.main-menu-trigger');
    const $closeMainMenuTrigger = $('.close-main-menu');

    $mainMenuTrigger.on('click', function() {
      $body.toggleClass('open-main-menu');
    });

    $closeMainMenuTrigger.on('click', function() {
      $body.removeClass('open-main-menu');
    });

    // special behavior
    $mainMenuTrigger.each(function(idx, elm) {
      const $elm = $(elm);

      // fallback home
      if ($elm.hasClass('fallback-home')) {
        // set the button and target variables
        const $button = $elm.children('a');
        const target = $button.attr('href');

        // if hash sign exists in the "target"
        if (target.indexOf('#') !== -1) {
          // get node id
          const nodeId = target.slice(target.indexOf('#'));

          // if node exists with the id of hash
          if ($(nodeId).length) {
            // add the proper css class to the element
            $elm.addClass('current-menu-item');

            // adjust button onclick behavior
            $button.on('click', function(e) {
              // scroll to node
              scrollTo(nodeId);
              e.preventDefault();
            });
          } else {
            // adjust button onclick behavior
            $button.on('click', function() {
              // redirect to site url + node id
              location.href = stonehenge.site_url + nodeId;
            });
          }
        }
      }
    });

    /**
     * Side Navigation
     *************************************************/
    const $sideNav = $('#side-nav');
    const $sections = $('.observed-section');

    // show the side navigation only if enabled (container element present) and
    // there are more than two sections
    if ($sideNav.length && $sections.length > 2) {
      // create an intersection observer
      const obSideNav = new IntersectionObserver(function(elements) {
        $.each(elements, function(idx, element) {
          // get target information
          const targetID = element.target.id;
          const $target = $(element.target);

          // animate target
          if (element.intersectionRatio && !$target.data('animate')) {
            $target.data('animate', true);
            $body.trigger('animate-' + targetID);
          }

          // animate target
          if (element.intersectionRatio >= 0.5 && !$target.data('animate-50')) {
            $target.data('animate-50', true);
            $body.trigger('animate-50-' + targetID);
          }

          // notify side nav
          if (element.intersectionRatio >= 0.5) {
            $sideNav.
                find('[href="#' + targetID + '"]').
                addClass('active').
                siblings().
                removeClass('active');
          }
        });
      }, {threshold: [0.0, 0.5]});

      // generate the markup for the side navigation
      const htmlSideNav = [];

      $sections.each(function(idx, elm) {
        // observe element
        obSideNav.observe(elm);

        // get target ID
        const targetID = elm.id;

        // create section anchor, utilizing the "scroll-to" class
        htmlSideNav.push(
            $('<a href="#' + targetID + '" class="scroll-to">&bull;</a>'));
      });

      // add the generated markup to the container
      $sideNav.prepend(htmlSideNav);
    }

    /**
     * Scroll
     *************************************************/
    let windowScrolled = 0;

    // Scroll Body Class
    $(window).on('scroll', function() {
      if ($(this).scrollTop() > $logoContainer.outerHeight()) {
        // prevent multiple runs
        if (windowScrolled === 0) {
          windowScrolled = 1;
          $body.addClass('scrolled');
        }
      } else {
        // prevent multiple runs
        if (windowScrolled === 1) {
          windowScrolled = 0;
          $body.removeClass('scrolled');
        }
      }
    });

    // Scroll To Hash on Page Load
    setTimeout(function() {
      if (location.hash) {
        // get target
        const target = location.hash;

        // reset scroll position
        window.scrollTo(0, 0);

        // scroll
        scrollTo(target);
      }
    }, 1);

    // Scroll To
    $('.scroll-to').on('click', function() {
      let $elm = $(this);

      // feature: search for anchor elements inside the triggering element
      if (!$elm.is('a')) {
        $elm = $elm.find('a');
        // sanity check
        if ($elm.length !== 1) {
          return false;
        }
      }

      // get target
      const target = $elm.attr('href');

      // get offset
      const offset = $elm.hasClass('offset');

      // scroll
      scrollTo(target, offset);

      return false;
    });

    /**
     * Forms
     *************************************************/
    $('.form').on('submit', function() {
      const $form = $(this);

      const options = $.extend(
          {
            handler: 'none'
          },
          $form.data());

      const $loading = $form.parent().find('.loading');
      const $success = $form.parent().find('.success');
      const $failure = $form.parent().find('.failure');

      const sqStatusSuccess = [
        {e: $loading, p: 'fadeOut', o: {duration: duration.short}},
        {e: $form, p: 'transition.expandOut', o: {duration: duration.medium}},
        {e: $success, p: 'transition.expandIn', o: {duration: duration.long}}
      ];

      const sqStatusFailure = [
        {e: $loading, p: 'fadeOut', o: {duration: duration.short}},
        {e: $form, p: 'transition.expandOut', o: {duration: duration.medium}},
        {e: $failure, p: 'transition.expandIn', o: {duration: duration.long}}
      ];

      // loading
      $loading.velocity(
          'fadeIn',
          {duration: duration.medium, display: 'inline-block'});

      // prevent splash of content
      if (!$form.parent().is('dialog')) {
        $form.parent().css('min-height', $form.parent().outerHeight());
      }

      $.ajax({
        url: stonehenge.ajax_url,
        method: 'post',
        cache: false,
        data: {
          _ajax_nonce: stonehenge.ajax_nonce,
          action: 'stonehenge_trigger_' + options.handler,
          data: $form.serialize(),
          location: window.location.href,
          referrer: document.referrer
        },
        error: function() {
          // eslint-disable-next-line new-cap
          $.Velocity.RunSequence(sqStatusFailure);
        },
        success: function(data) {
          if (data.success) {
            // eslint-disable-next-line new-cap
            $.Velocity.RunSequence(sqStatusSuccess);
          } else {
            // eslint-disable-next-line new-cap
            $.Velocity.RunSequence(sqStatusFailure);
          }
        }
      });

      return false;
    });

    /**
     * Discover Widget
     *************************************************/
    const $discoverWidget = $('.discover-widget');

    if ($discoverWidget.length) {
      $discoverWidget.each(function() {
        const $elm = $(this);
        const $actionButton = $elm.find('.action-button');
        const $content = $elm.find('.content');

        // hide content, progressive enhancement
        $content.hide();

        // action button event handler
        $actionButton.on('click', function() {
          // toggle the content animation
          if ($elm.hasClass('expanded')) {
            $elm.removeClass('expanded');
            $content.velocity('transition.slideUpOut', duration.short);
          } else {
            $elm.addClass('expanded');
            $content.velocity('transition.slideDownIn', duration.medium);
          }

          // preventDefault && stopPropagation
          return false;
        });
      });
    }

    /**
     * Swiper
     *************************************************/
    const $swiperContainer = $('.swiper-container.init');

    if ($swiperContainer.length) {
      $swiperContainer.each(function() {
        new Swiper(this, {
          speed: duration.long,
          autoplay: {
            delay: duration.long * 6,
            disableOnInteraction: true
          },
          slidesPerView: 2,
          spaceBetween: 64,
          centeredSlides: true,
          grabCursor: true,
          preventClicks: true,
          roundLengths: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          breakpoints: {
            1279: {
              slidesPerView: 1
            }
          }
        });
      });
    }

    /**
     * Blog - Featured Swiper
     *************************************************/
    const $featuredSwiperContainer = $('.featured .swiper-container');

    if ($featuredSwiperContainer.length) {
      $featuredSwiperContainer.each(function() {
        new Swiper(this, {
          speed: duration.long,
          autoplay: {
            delay: duration.long * 6,
            disableOnInteraction: true
          },
          slidesPerView: 2,
          spaceBetween: 32,
          centeredSlides: true,
          grabCursor: true,
          preventClicks: true,
          roundLengths: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          breakpoints: {
            1279: {
              slidesPerView: 1,
              spaceBetween: 16,
              centeredSlides: true
            }
          }
        });
      });
    }

    /**
     * Blog - Latest
     *************************************************/
    const $latest = $('.latest');

    if ($latest.length && $latest.length === 1) {
      const $content = $latest.find('.content');
      const $loading = $latest.find('.loading');
      const $message = $latest.find('.message');

      let loading = false;
      let exhausted = false;
      let paged = 1;

      // hide loading animation
      $loading.hide();

      // hide message
      $message.hide();

      // create an intersection observer
      const obFooter = new IntersectionObserver(function(elements) {
        const elm = elements[0];

        if (!loading && !exhausted && elm.isIntersecting) {
          // set loading state
          loading = true;

          // unobserve
          obFooter.unobserve(elm.target);

          // finish all animations
          $loading.velocity('finish');

          // show loading animation
          $loading.velocity('fadeIn', {duration: duration.medium});

          // set args
          const args = $.extend(
              true,
              {
                _ajax_nonce: stonehenge.ajax_nonce,
                action: 'stonehenge_trigger_get_blog_posts',
                posts_per_page: stonehenge.posts_per_page,
                paged: paged
              },
              $latest.data());

          // get blog posts
          $.getJSON(stonehenge.ajax_url, args).
              done(function(data) {
                // hide loading animation
                $loading.velocity('fadeOut', {duration: duration.short});

                if (data.success === true) {
                  // add content to DOM
                  $content.append(data.data.content);

                  // next page
                  paged++;
                } else {
                  // add content to DOM
                  $message.text(data.data.content);

                  // show message
                  $message.velocity('fadeIn', {duration: duration.medium});

                  // exhausted
                  exhausted = true;
                }
              }).
              always(function() {
                // observe
                obFooter.observe(elm.target);

                // set loading state
                loading = false;
              });
        }
      }, {threshold: [0.0]});

      // observe footer
      obFooter.observe($('main + footer')[0]);
    }
  }); // load
}(jQuery, window, document));
